body {
  background-color: black;
  margin: 0;
}

:root {
  --app-height: 100vh;
}

@font-face {
  font-family: 'Bicyclette';
  src: url('./assets/Bicyclette/Bicyclette-Regular.otf') format('opentype');
}

.App {
  text-align: center;
  background-color: black;
  min-height: var(--app-height);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: white;
  font-family: 'Bicyclette';
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  opacity: 1;
  animation: fadeInAnimation 3s ease-in-out;
}

.text-overlay-1 {
  color: white;
  font-size: 18px;
  text-align: center;
  margin-bottom: 10px;
  padding-top: 260px;
}

.download-button {
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.button-dummy {
  width: 100%;
  height: 80px;
  cursor: pointer;
}

.text-overlay-2 {
  color: white;
  font-size: 13px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 5px;
}

.text-overlay-3 {
  color: white;
  margin-top: 1px;
  font-size: 35px;
  text-align: center;
}